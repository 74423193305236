.progress-circle {
	margin: 0px;
	width: 80px;
	height: 80px;
	position: relative;
	/*margin: 0 auto 20px auto;*/
}

.progress-semi-circle {
	width: 80px;
	height: 50px;
}

.clickable {
	cursor: pointer;
}

textarea {
	height: 150px;
}

.swal2-icon.swal2-warning {
	border-color: $primary !important;
	color: $primary !important;
}

.swal2-styled.swal2-confirm {
	background-color: $primary !important;
}

.sidenav {
	li {		
		
		a {			
			&.selected, &:hover {
				border: 1px solid $primary !important;
				background-color: rgba($primary, .08);	
				font-weight: bolder;	

				i {
					font-size: 1.2rem;
				}			
			}
		}
		
	}
}