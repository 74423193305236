//
// Section separator shapes
// --------------------------------------------------


.shape {
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 2;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
  }
  &.bg-light,
  &.bg-body,
  &.bg-primary,
  &.bg-secondary,
  &.bg-dark,
  &.bg-darker {
    background-color: transparent !important;
  }

  &.bg-light { color: $white !important; }
  &.bg-body { color: $body-bg !important; }
  &.bg-primary { color: $primary !important; }
  &.bg-secondary { color: $gray-100!important; }
  &.bg-dark { color: $dark !important; }
  &.bg-darker { color: darken($gray-900, 5%) !important; }
}

.shape-top,
.shape-bottom {
  width: 100%;
  left: 0;
}

.shape-right,
.shape-left {
  height: 100%;
  left: 0;
  top: 0;

  svg {
    top: -2px;
    width: auto;
    height: 102%;
    height: calc(100% + 5px);
  }
}

.shape-top {
  top: 0;
  margin-top: -1px;
}

.shape-bottom {
  bottom: 0;
  margin-bottom: -1px;
}

.shape-right svg {
  left: auto;
  right: 0;
  margin-right: -1px;
}

.shape-left svg {
  left: 0;
  right: auto;
  margin-left: -1px;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .shape-right,
  .shape-left { display: none; }
}

.shape-slant {
  padding-bottom: 8.7%; //= 260/3000 actual svg shape height/width
}
.shape-curve-side {
  padding-bottom: 8.4%; //= 250/3000 actual svg shape height/width
}
.shape-curve {
  padding-bottom: 6.2%; //= 185.4/3000 actual svg shape height/width
}
