// Variables
@import 'theme';

$fa-font-path: "/fonts" !default;
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

@import "node_modules/@splidejs/splide/src/css/themes/default/index.scss";

.navbar-brand {
	width: 150px;
	padding: 0;
}
.booking-cal {
	.week-selector {

	}

	.card {
		border-width: 3px;
		border-color: $gray-400;
		border-radius: 15px;
		min-height: 120px;		

		.date-container {
			h2 { 
				font-size: 43px; 
				.divider {
					font-size: 0.8em;
					font-weight: normal;
				}
				small { 
					text-transform: lowercase; 
					vertical-align: super; 
					font-size: 0.5em;
				}			
			}
		}

		&.today {
			.date-container { 
				border: 5px solid $primary; 
				border-radius: 15px; 
				padding: 10px;
			}
		}

		&.past { 
			.date-container, table td {
				h2 { color: $gray-400; }
				color: $gray-400 !important;
			}
		}

		.booking-name, .blocked {
			color: $primary;
			font-size: 0.9rem;
			border: 2px solid $primary;
			border-radius: 15px;
			padding: 6px 15px;
			background-color: #FFF;

			&.selected {
				border-style: dashed;
			}
		}

		.blocked {
			color: $info;			
			border-color: $info;
		}		

		table tr {

			td { padding: 0; }

			&.has-booking td { padding:0.375rem 0.375rem; }

			&.is-blocked {
				td { padding:0.375rem 0.375rem; }
				&:hover { cursor: default; }
			}

			&:hover {
				background-color: $gray-400;
				cursor: pointer;

				.booking-name {
					background-color: $primary;			
					color: #FFF;
				}				
			}
		}
	}
}


