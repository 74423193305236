//
// Off-canvas
// --------------------------------------------------


// Off-canvas base styles

.offcanvas {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  max-width: $offcanvas-width;
  height: 100%;
  will-change: transform, box-shadow;
  transform: translateX(-100%);
  transition: transform .4s cubic-bezier(.165, .84, .44, 1), box-shadow .3s ease;
  background-color: $offcanvas-bg;
  z-index: $zindex-offcanvas;
  
  // Off-canvas on the end
  &.offcanvas-end {
    right: 0;
    left: auto;
    transform: translateX(100%);
  }

  // Off-canvas show
  &.show {
    transform: none;
    box-shadow: $offcanvas-box-shadow;
  }
}


// Off-canvas caps: Header and/or Footer

.offcanvas-cap {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: $offcanvas-padding-y $offcanvas-padding-x;

  .bg-dark & {
    background-color: rgba($white, .05);
  }
}


// Off-canvas body (content holder)

.offcanvas-body {
  height: 100%;
  padding: 0 $offcanvas-padding-x;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    background-color: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-bg;
    @include border-radius($scrollbar-width / 2);
  }
  & > .simplebar-track {
    display: block;
    background-color: transparent;
  }
  .simplebar-vertical { margin-right: .1875rem; }
}


// Backdrop

.offcanvas-backdrop {
  @extend .modal-backdrop;
  transition: opacity .2s ease;
  opacity: 0;

  &.show {
    opacity: .5;
  }
}


// Kill the body scroll when offcanvas is open

.offcanvas-open { overflow: hidden; }


// Sidebar toggle btn

.sidebar-toggle {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  z-index: $zindex-btn-scroll-top;

  &.right-15 {
    right: 15px;
    width: calc(100% - 15px);
  }
}


// Control the breakpoint at which off-canvas expands or collapses
@include media-breakpoint-up(lg) {

  // Expand off-canvas from lg breakpoint
  .offcanvas-expand {
    transform: none;
    z-index: $zindex-fixed + 5;
    &.offcanvas-end { transform: none; }
  }

  // Collapse off-canvas from lg breakpoint
  .offcanvas-collapse {
    display: block;
    position: static;
    top: auto;
    left: auto;
    max-width: 100%;
    height: auto;
    transform: none;
    background-color: transparent;

    &.offcanvas-end {
      right: auto;
      transform: none;
    }

    .offcanvas-cap { display: none; }

    .offcanvas-body {
      padding: 0;
      overflow-x: hidden;
    }
  }

  // Off-canvas enabled container (for layouts with fixed side navigation)
  .offcanvas-enabled {
    padding-left: $offcanvas-width + 1.5rem;

    &.offcanvas-end {
      padding: {
        right: $offcanvas-width + 1.5rem;
        left: 0;
      }
    }
  }
}


@include media-breakpoint-down(lg) {
  .offcanvas-collapse {
    .offcanvas-body {
      .navbar-nav {
        padding-top: 0;
        .nav-item {
          border: {
            top: 0;
            bottom: $border-width solid $border-color !important;
          }
        }
        .nav-link {
          color: $navbar-light-color;
          &:hover { color: $navbar-light-hover-color; }
          &.active { color: $navbar-light-active-color; }
          &.disabled { color: $navbar-light-disabled-color; }
        }
        .show > .nav-link,
        .active > .nav-link { color: $navbar-light-active-color; }
        .dropdown-menu .dropdown-item {
          color: $navbar-light-color;
          &:hover { color: $navbar-light-hover-color; }
          &.active { color: $navbar-light-active-color; }
          &.disabled { color: $navbar-light-disabled-color; }
        }
        .dropdown-header {
          color: $dropdown-header-color;
          border-color: $border-color;
        }
      }
    }
  }
}


.widget-nav {

  .nav-link {
    margin: {
      right: -$offcanvas-padding-x;
      bottom: .75rem;
      left: -$offcanvas-padding-x;
    }
    padding: 0 $offcanvas-padding-x;

    &.active {
      position: relative;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: .125rem;
        height: 100%;
        background-color: $nav-link-light-active-color;
        content: '';
      }
    }
  }
}
